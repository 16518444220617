import { Pagination, fetchUtils } from 'react-admin'; //https://marmelab.com/react-admin/Pagination.html
import simpleRestProvider from 'ra-data-simple-rest';


function HTTPSetter (url, options = {}) {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    options.headers.set('Access-Control-Allow-Origin', process.env.REACT_APP_HOST_URL);

    return fetchUtils.fetchJson(url, options);
};


let dataProvider = simpleRestProvider(process.env.REACT_APP_HOST_URL+ '/api/v1', HTTPSetter)

export {dataProvider, HTTPSetter}