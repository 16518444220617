import axios from 'axios'
import { fetchFingerprint } from 'utils';

const authProvider = {
    // send username and password to the auth server and get back credentials
    login: params => {
        let fingerprint = fetchFingerprint();
        let login_data = {
            authId: params.username,
            password: params.password,
            machineId: fingerprint,
            apiKey: process.env.REACT_APP_API_KEY
        };

        return axios.post("/api/v1/token", login_data)
        .then(response => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.data;
        })
        .then(auth => {
            localStorage.setItem("token", auth.token);
            axios.defaults.headers.common['Authorization'] = `Bearer ${auth.token}`;
        }).catch((err) => {
            throw new Error('login failed')
        });    
    },

    // when the dataProvider returns an error, check if this is an authentication error
    checkError: error => Promise.resolve(),

    // when the user navigates, make sure that their credentials are still valid
    checkAuth: params => {
        const token = localStorage.getItem("token", "");
        if (!token) {
            return Promise.reject()
        }
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return Promise.resolve();

        // return axios.get("/api/v1/user/roles")
        // .then(response => {
        //     if (response.status < 200 || response.status >= 300) {
        //         throw new Error(response.statusText);
        //     }
        //     return response.data;
        // })
        // .then(auth => {
        //     let musioAdmin = auth.roles.includes("musio_admin")
        //     let musioSupport = auth.roles.includes("musio_support")

        //     if (musioAdmin == true || musioSupport == true) {
        //         return Promise.resolve()
        //     }
        //     return Promise.reject();

        // }).catch((err) => {
        //     throw new Error('checkAuth failed')
        // });
    },
    // remove local credentials and notify the auth server that the user logged out
    logout: () => {
        localStorage.removeItem("token")
        axios.defaults.headers.common['Authorization'] = '';
        return Promise.resolve()
    },
    // get the user's profile
    getIdentity: () => Promise.resolve(),
    // get the user permissions (optional)
    getPermissions: () => Promise.resolve(),
};

export default authProvider;
