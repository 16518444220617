import { List, Show, Edit, FilterButton, useNotify, Create, required, ExportButton } from 'react-admin'
import { Datagrid, FormDataConsumer, DateField, DateInput, TextField, ReferenceField, AutocompleteInput, NumberField, NumberInput, ReferenceInput, SelectField, SelectInput, PasswordInput, BooleanField, EmailField, NullableBooleanInput, SimpleShowLayout, TopToolbar, ListButton, ShowButton, SimpleForm, TextInput} from 'react-admin';
import licenseTypes from './licenseTypes';


const LicenseCodeListActions = () => (
    <TopToolbar>
        <ExportButton maxResults={100000}/>
    </TopToolbar>
);

const licenseCodeFilters = [
    <ReferenceInput label="Bundle" source="bundleId" reference="license-code-bundles" alwaysOn>
        <AutocompleteInput
            suggestionLimit="5"
        />
    </ReferenceInput>,
    <NullableBooleanInput source="consumed" alwaysOn/>
];

export const LicenseCodeList = () => (
    <List filters={licenseCodeFilters} actions={<LicenseCodeListActions/>}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="id"/>
            <ReferenceField source="bundleId" reference="license-code-bundles" />
            <TextField source="token" sx={{ fontFamily: 'courier' }}/>
            <BooleanField source="consumed"/>
        </Datagrid>
    </List>
);
