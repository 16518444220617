//http://localhost:3001/#/license-codes?displayedFilters=%7B%7D&filter=%7B%22bundleId%22%3A73%7D&order=ASC&page=1&perPage=10&sort=id


import React, {useState, Fragment, Box, Typography} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useNotify, useRecordContext, useRedirect } from 'react-admin';

const ShowCodesForBundle = () => {
    const notify = useNotify();
    const record = useRecordContext();
    const redirect = useRedirect();

    const handleClick = () => {
        redirect(`/license-codes?filter={"bundleId":${record.id}}`)
    };

    return (
        <Fragment>
            <Button size="sm" color="primary" onClick={handleClick}>View Codes</Button>
        </Fragment>
    );
};

ShowCodesForBundle.propTypes = {
    record: PropTypes.object,
};

export default ShowCodesForBundle;