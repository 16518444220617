import FingerprintJS from '@fingerprintjs/fingerprintjs'

export const generateFingerprint = ()=> {
    // create the fingerprint and add it to the portalStore
    const getFingerprint = async ()=> {  //this function should be called via requestIdleCallback
        FingerprintJS.load()
        .then(fp => fp.get())
        .then(result => {
            // This is the visitor identifier:
            const visitorId = result.visitorId;
            localStorage.setItem("fingerprint", visitorId);
        })
    };

    //trigger the fingerprinting
    if (window.requestIdleCallback) { //something browsers provide when loading is done
        requestIdleCallback(getFingerprint);
    } else {
        setTimeout(getFingerprint, 500);
    };
};

export const fetchFingerprint = ()=> {
    return localStorage.getItem("fingerprint", "");
}
