import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useNotify, useRecordContext, Confirm, useRedirect, useRefresh } from 'react-admin';
import axios from 'axios';

const DepositCodesToBundle = () => {
    const notify = useNotify();
    const record = useRecordContext();
    const refresh = useRefresh();

    const handleDeposit = (count) => {
        axios({method: "POST", url: `/api/v1/license-code-bundles/${record.id}`, data: {count}})
        .then(resp=>{
            notify(`Deposited ${count} codes`);
            refresh();
        })
        .catch(err => {
            if (err.response == 400) {
                //TODO: bad request
            }
            else if (err.response.status == 403) {
                notify(err.response.data.error.toString());
            }
        });
    };

    const handleClick = () => {
        let count = prompt('Number of codes to add (max 2500)', '0');
        if (count == null)
            return;

        count = parseInt(count);
        if (Number.isInteger(count))
        {
            if (count > 2500) {
                notify('Cannot add more than 2500 codes')
            }
            else {
                notify('Please wait (~1 minute)', { autoHideDuration: 45000 })
                handleDeposit(count);
            }
        }
    };

    return (
        <Fragment>
            <Button size="sm" color="error" onClick={handleClick}>Add Codes</Button>
        </Fragment>
    );
};

DepositCodesToBundle.propTypes = {
    record: PropTypes.object,
};

export default DepositCodesToBundle;