import React, {useState, Fragment, Box, Typography} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { Dialog } from '@mui/material';
import { useNotify, useRecordContext } from 'react-admin';
import axios from 'axios';

const ViewStripeButton = () => {
    const notify = useNotify();
    const record = useRecordContext();

    const handleLink = () => {
        console.log('LINK STRIPE')
    }

    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(true);
        axios({method: "GET", url: `/api/v1/users/${record.id}/stripe`})
        .then(resp=>{
            console.log(resp.data.url);
            window.open(resp.data.url, '_blank');
        })
        .catch(err => {
            if (err.response == 400) {
                //TODO: bad request
            }
            else if (err.response.status == 403) {
                notify(err.response.data.error.toString());
            }
            else if (err.response.status == 422) {
                notify(err.response.data.error.toString());
            }
        });
    };

    return (
        <Fragment>
            <Button size="sm" color="primary" disabled={record === undefined} onClick={handleClick}>Goto Stripe</Button>
        </Fragment>
    );
};

ViewStripeButton.propTypes = {
    record: PropTypes.object,
};

export default ViewStripeButton;