import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useNotify, useRecordContext, Confirm, useRedirect } from 'react-admin';
import axios from 'axios';

const DeleteUserButton = () => {
    const notify = useNotify();
    const record = useRecordContext();
    const handleDelete = () => {
        axios({method: "DELETE", url: `/api/v1/users/${record.id}`})
        .then(resp=>{
            notify(`Deleted account for ${record.email}`);
        })
        .catch(err => {
            if (err.response == 400) {
                //TODO: bad request
            }
            else if (err.response.status == 403) {
                notify(err.response.data.error.toString());
            }
        });
    };

    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(true);
    };

    const redirect = useRedirect();
    const handleDialogConfirm= () => {
        setOpen(false);
        handleDelete();
        redirect("/users/")
    };

    const handleDialogClose = () => {
        setOpen(false);
    };
    const userEmail = record ? record.email : 'loading';
    return (
        <Fragment>
            <Button size="sm" color="error" onClick={handleClick}>Delete</Button>
            <Confirm
                isOpen={open}
                loading={false}
                title={`Delete: ${userEmail}`}
                content={"Are you sure you want to delete this account?"}
                onConfirm={handleDialogConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
};

DeleteUserButton.propTypes = {
    record: PropTypes.object,
};

export default DeleteUserButton;