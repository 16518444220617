export default  [
        { id: 'NFR', name: 'NFR' },
        { id: 'EDU', name: 'EDU' },
        { id: 'EDULAB', name: 'EDULAB'},
        { id: 'AAF', name: 'AAF'},
        { id: 'LIFETIME', name: 'LIFETIME' },
        { id: 'PROMO', name: 'PROMO' },
        { id: 'RESALE', name: 'RESALE' },
        { id: 'BRAND_SWAP', name: 'BRAND_SWAP' },
        { id: 'MUSIO_ONE', name: 'MUSIO_ONE'},
        { id: 'MUSIO_ONE_UPGRADE', name: 'MUSIO_ONE_UPGRADE'}
];
