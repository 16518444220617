import React, {useState, Fragment, Box, Typography} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { Dialog } from '@mui/material';
import { useNotify, useRecordContext } from 'react-admin';
import axios from 'axios';

const LinkStripeAccountButton = () => {
    const notify = useNotify();
    const record = useRecordContext();

    const handleLink = () => {
        console.log('LINK STRIPE')
    }

    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(true);
        const stripeCustomerId = prompt('Enter Customer Id (eg. cus_NmDsK...)')
        if (stripeCustomerId != null) {

            if (stripeCustomerId.startsWith('cus_')) {
                axios({method: "POST", url: `/api/v1/users/${record.id}/link-stripe`, data: {stripeCustomerId}})
                .then(resp=>{
                    notify(`Updated stripe customer id for ${record.email}`);
                })
                .catch(err => {
                    if (err.response == 400) {
                        //TODO: bad request
                    }
                    else if (err.response.status == 403) {
                        notify(err.response.data.error.toString());
                    }
                    else if (err.response.status == 422) {
                        notify(err.response.data.error.toString());
                    }
                });
            }
            else {
                notify('Invalid customer id entered')
            }    
        }
    };

    return (
        <Fragment>
            <Button size="sm" color="primary" disabled={record === undefined || record.stripeCustomerId} onClick={handleClick}>Link Stripe</Button>
        </Fragment>
    );
};

LinkStripeAccountButton.propTypes = {
    record: PropTypes.object,
};

export default LinkStripeAccountButton;
