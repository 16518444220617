import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useNotify, useRecordContext } from 'react-admin';
import axios from 'axios';

const RecoverAccountButton = () => {
    const notify = useNotify();
    const record = useRecordContext();
    const handleClick = () => {
        axios({method: "POST", url: `/api/v1/users/${record.id}/resend`, data: {'token_type': 'recover'}})
        .then(resp=>{
            notify(`Sent account recovery to ${record.contactName}`);
        })
        .catch(err => {
            if (err.response == 400) {
                //TODO: bad request
            }
            else if (err.response.status == 422) {
                notify(err.response.data.error.toString());
            }
        });
    };

    return (
        <Button size="sm" color="primary" onClick={handleClick}>Recover Account</Button>
    );
};

RecoverAccountButton.propTypes = {
    record: PropTypes.object,
};

export default RecoverAccountButton;