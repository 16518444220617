import { List, Show, ShowBase, Edit, FilterButton, useNotify, Create, required, useRecordContext } from 'react-admin'
import { Box, Grid } from '@mui/material';
import { Datagrid, FormDataConsumer, DateField, DateInput, TextField, AutocompleteInput, NumberField, NumberInput, ReferenceInput, SelectField, SelectInput, PasswordInput, BooleanField, EmailField, BooleanInput, SimpleShowLayout, TopToolbar, ListButton, ShowButton, SimpleForm, TextInput} from 'react-admin';
import licenseTypes from './licenseTypes';
import DepositCodesToBundle from 'components/DepositCodesToBundle'
import ShowCodesForBundle from 'components/ShowCodesForBundle'
import {PerpetualVersionList, PerpetualVersionChoices} from './perpetualVersions';


const LicenseCodeBundleListActions = () => (
    <TopToolbar>
    </TopToolbar>
);

const licenseCodeBundleFilters = [
    <TextInput label="Purchaser" source="purchaser" defaultValue="" alwaysOn/>,
    <SelectInput label="Subject" source="subject" defaultValue="" alwaysOn choices={licenseTypes}/>,
];

export const LicenseCodeBundleList = () => (
    <List filters={licenseCodeBundleFilters}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="id"/>
            <TextField source="subject"/>
            {/* <NumberField source="durationCount"/>
            <TextField source="duration"/> */}
            <NumberField source="count"/>

            <TextField source="purchaser"/>
            <TextField source="description"/>
            <BooleanField source="consignment"/>
            <NumberField source="price" label="price" options={{ style: 'currency', currency: 'USD' }}/>
        </Datagrid>
    </List>
);

const LicenseCodeBundleActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ShowCodesForBundle record={data}/>
        <DepositCodesToBundle record={data}/>
    </TopToolbar>
);

const ConditionalDurationField = () => {
    const record = useRecordContext();
    return record && !record.perpetualVersion ? <SimpleShowLayout><NumberField source="durationCount"/><TextField source="duration"/></SimpleShowLayout>: null;
}

const ConditionalPerpetualVersionField = () => {
    const record = useRecordContext();
    return record && record.perpetualVersion ? <SimpleShowLayout><NumberField source="perpetualVersion" /></SimpleShowLayout> : null;
}

export const LicenseCodeBundleShow = (props) => (
    <Show {...props} actions={<LicenseCodeBundleActions/>}>
        <Grid container>
            <Grid item xs={3}>
                <SimpleShowLayout>
                    <TextField source="subject"/>
                    <NumberField source="count"/>
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={3}>
                <ConditionalDurationField/>
                <ConditionalPerpetualVersionField/>
            </Grid>
            <Grid item xs={3}>
                <SimpleShowLayout>
                    <TextField source="purchaser"/>
                    <TextField source="description"/>
                    <BooleanField source="consignment"/>
                    <NumberField source="price" label="price" options={{ style: 'currency', currency: 'USD' }}/>
                    <TextField source="id"/>
                </SimpleShowLayout>
            </Grid>
        </Grid>
    </Show>
);

const hasDuration = (subject) => {
    return !['LIFETIME', 'EDULAB', 'MUSIO_ONE', 'MUSIO_ONE_UPGRADE'].includes(subject)
}

const isMusio1 = (subject) => {
    return ['MUSIO_ONE', 'MUSIO_ONE_UPGRADE'].includes(subject)
}

const Musio1VersionInput = (props) => (
    <SelectInput label="Musio 1 Version" choices={PerpetualVersionChoices} defaultValue={PerpetualVersionList.length} {...props}/>
);

export const LicenseCodeBundleCreate = (props) => {
    return (
        <Create {...props} redirect="list">
            <SimpleForm sanitizeEmptyValues={true}>
                <SelectInput source="subject" choices={licenseTypes} defaultValue="RESALE" validate={required()}/>
                <FormDataConsumer>
                    {({ formData, ...rest }) => hasDuration(formData.subject) &&
                        <div>
                            <NumberInput source="durationCount" label="Count" defaultValue={1}/>
                            <SelectInput source="duration" choices={[
                                { id: 'months', name: 'months' },
                                { id: 'years', name: 'years' },
                                { id: 'weeks', name: 'weeks' }
                            ]} defaultValue="years" validate={required()}/>
                        </div>
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) => isMusio1(formData.subject) &&
                        <div>
                            <Musio1VersionInput source="perpetualVersion" validate={required()}/>
                        </div>
                    }
                </FormDataConsumer>

                <NumberInput source="count" label="Num Licenses" defaultValue={1} min={1}/>
                <TextInput source="purchaser" label="Purchaser"  validate={required()} fullWidth />
                <TextInput source="description" label="Description (eg: Sept 2023 Promo)" validate={required()} fullWidth/>
                <BooleanInput source="consignment" label="Consignment" defaultValue={false} validate={required()}/>
                <NumberInput source="price" label="$/license" options={{ style: 'currency', currency: 'USD' }} validate={required()} defaultValue={59}/>

            </SimpleForm>
        </Create>
    );
};
