import { List, Show, Edit, FilterButton, useNotify, Create, required, ExportButton } from 'react-admin'
import { Datagrid, FormDataConsumer, DateField, DateInput, TextField, ReferenceField, AutocompleteInput, NumberField, NumberInput, ReferenceInput, SelectField, SelectInput, PasswordInput, BooleanField, EmailField, NullableBooleanInput, SimpleShowLayout, TopToolbar, ListButton, ShowButton, SimpleForm, TextInput} from 'react-admin';
import licenseTypes from './licenseTypes';


const LicenseCodeDirectSaleListActions = () => (
    <TopToolbar>
    </TopToolbar>
);

const licenseCodeDirectSaleFilters = [
    <TextInput label="Txn Id" source="transactionId" defaultValue="" alwaysOn/>
];

export const LicenseCodeDirectSaleList = () => (
    <List filters={licenseCodeDirectSaleFilters} actions={<LicenseCodeDirectSaleListActions/>}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="id"/>
            <TextField label="Txn Id" source="transactionId"/>
            <TextField source="license_code"/>
        </Datagrid>
    </List>
);
