import { List, Show, Edit, FilterButton, useNotify, Create, required } from 'react-admin'
import { Datagrid, FormDataConsumer, TextField, PasswordInput, BooleanField, EmailField, BooleanInput, SimpleShowLayout, TopToolbar, ListButton, ShowButton, SimpleForm, TextInput} from 'react-admin';
import ResendSignupButton from 'components/ResendSignupButton';
import RecoverAccountButton from 'components/RecoverAccountButton';
import RestartTrialButton from 'components/RestartTrialButton';
import DeleteUserButton from 'components/DeleteUserButton';
import ViewStripeButton from 'components/ViewStripeButton';
import LinkStripeAccountButton from 'components/LinkStripeAccountButton';

const UserListActions = () => (
    <TopToolbar>
    </TopToolbar>
);

const userFilters = [
    <TextInput label="Email" source="email" defaultValue="" alwaysOn/>,
    <TextInput label="Name" source="contactName" defaultValue="" />
];

export const UserList = () => (
    <List filters={userFilters}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="name"/>
            <EmailField source="email"/>
            <BooleanField source="verified"/>
            <TextField source="uuid"/>
            <TextField source="activeLicense"/>
            <TextField source="activeRecurring"/>
        </Datagrid>
    </List>
);

const UserShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <LinkStripeAccountButton record={data}/>
        <ViewStripeButton record={data}/>
        <ResendSignupButton record={data}/>
        <RecoverAccountButton record={data}/>
        <RestartTrialButton record={data}/>
        <DeleteUserButton/>
    </TopToolbar>
);

export const UserShow = (props) => (
    <Show {...props} actions={<UserShowActions/>}>
        <SimpleShowLayout>
            <TextField source="name"/>
            <TextField source="email"/>
            <BooleanField source="verified"/>
            <TextField source="verifyUrl"/>
            <TextField source="uuid"/>
            <TextField source="activeLicense"/>
            <TextField source="activeRecurring"/>
        </SimpleShowLayout>
    </Show>
);

export const UserCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="email" label="Email" validate={[required()]} fullWidth  />
            <BooleanInput label="Skip Validation" source="skipValidation" />
            <FormDataConsumer>
                 {({ formData, ...rest }) => formData.skipValidation &&
                      <TextInput source="contactName" label="Full Name" disabled={!formData.skipValidation} fullWidth />
                 }
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) => formData.skipValidation &&
                      <PasswordInput source="password" label="Password" disabled={!formData.skipValidation} fullWidth  />
                 }
            </FormDataConsumer>
            
        </SimpleForm>
    </Create>
);
